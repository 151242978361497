<template>
  <div>
    <ul>
      <li v-for="group of grouped" :key="group[0].property + Date.now()">
        <div class="group">
          <div class="item-border">{{ '[' }}</div>
          <div class="truncate property-name">{{ `${group[0].property}:` }}</div>
          <li v-for="diff of group" :key="diff.property + Date.now()">
            <div class="truncate old-value" :title="getValue(diff.originalProperty, diff.oldValue)">
              {{ getValue(diff.originalProperty, diff.oldValue) }}
            </div>
          </li>
          <li v-for="diff of group" :key="diff.property + Date.now()">
            <div class="truncate new-value" :title="getValue(diff.originalProperty, diff.newValue)">
              {{ getValue(diff.originalProperty, diff.newValue) }}
            </div>
          </li>
          <div class="item-border">{{ ']' }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    diffs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    grouped() {
      return this.groupBy(this.diffs, 'property');
    }
  },
  methods: {
    groupBy(collection, property) {
      var i = 0,
        val,
        index,
        values = [],
        result = [];
      for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1) result[index].push(collection[i]);
        else {
          values.push(val);
          result.push([collection[i]]);
        }
      }
      return result;
    },
    getValue(property, value) {
      try {
        if (property === 'attachments' && value) {
          const parts = atob(value).split('\\');
          return parts[parts.length - 1];
        }

        return value;
      } catch (error) {
        return value;
      }
    }
  }
};
</script>

<style scoped>
ul {
  display: table;
  list-style-type: none;
  overflow: hidden;
}

li {
  float: left;
  vertical-align: middle;
}

.group {
  text-align: center;
  font-size: 0.7rem;
}

.item-border {
  display: inline;
  margin-right: 0.2rem;
  font-size: 0.75rem;
  float: left;
}

.property-name {
  font-weight: 700;
  display: inline;
  margin-right: 0.2rem;
  float: left;
}

.old-value {
  color: var(--theme-error);
  text-decoration: line-through;
  display: inline;
  margin-right: 0.2rem;
  font-style: italic;
  float: left;
}

.new-value {
  color: var(--theme-success);
  display: inline;
  margin-right: 0.2rem;
  float: left;
}

.truncate {
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
</style>
